<script setup lang="ts">
import { Media } from '@libero/api-client/media/types';
import Modal from '@libero/ui-framework/Modal/Modal.vue';
import ModalContent from '@libero/ui-framework/Modal/ModalContent.vue';
import { getMediaIsImage } from '@libero/utilities/media';
import { computed, ref } from 'vue';

interface Props {
  media: Media;
}

const props = defineProps<Props>();

const isOpen = ref(false);

const toggleVisibility = () => {
  isOpen.value = !isOpen.value;
};

const isImage = computed(() => getMediaIsImage(props.media));
</script>

<template>
  <template v-if="isImage">
    <slot :openModal="toggleVisibility" />

    <Modal :title="media.file_name" :isOpen="isOpen" :onCancel="toggleVisibility">
      <ModalContent>
        <img :src="media.temporary_url" />
      </ModalContent>
    </Modal>
  </template>
</template>
