const baseUrl = ((): string => {
  const { origin } = location;

  if (origin.endsWith(':8081')) {
    return import.meta.env.VITE_API_OVERVIEW_URL;
  }

  if (origin.endsWith(':8080') || origin.endsWith(':8082') || origin.endsWith(':8083')) {
    return import.meta.env.VITE_API_TENANT_URL;
  }

  if (origin.includes('doeeenmelding.nl')) {
    return origin.replace('doeeenmelding.nl', 'cityview.nl');
  }

  if (origin.includes('sensorregister.nl')) {
    return origin.replace('sensorregister.nl', 'cityview.nl');
  }

  return origin;
})();

export const generateUrl = (path?: string): string => {
  const strippedPath = path?.replace(/^\/+/, '');
  return [baseUrl, strippedPath].filter(Boolean).join('/');
};

export const generateUrlGenerator =
  (basePath: string) =>
  (path?: string): string =>
    generateUrl([basePath, path].filter(Boolean).join('/'));
