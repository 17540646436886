import type { Tenant } from '@libero/api-client/tenant/types';
import { jsonApi } from '@libero/utilities/api-client';

const tenant = jsonApi('api/tenant');

const getTenant = (): Promise<Tenant> => {
  return tenant.get();
};

export const tenantApi = {
  getTenant,
};
