<script setup lang="ts">
import type { Color } from '@libero/types/Color';
import { computed } from 'vue';

interface Props {
  tag?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p'
    | 'span'
    | 'strong'
    | 'li'
    | 'dt'
    | 'dd'
    | 'div';
  type?: 'heading' | 'body' | 'base';
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
  color?: Color;
  opacity?: number;
  mb?: '025' | '050' | '075' | '100' | '150';
  align?: 'center' | 'right';
  bold?: boolean;
  semiBold?: boolean;
  margin?: boolean;
  uppercase?: boolean;
  truncated?: boolean;
  label?: string;
  muted?: boolean;
  lineHeight?: string;
  width?: string;
  maxWidth?: string;
  isDark?: boolean;
  isHandle?: boolean;
  hasWhitespace?: boolean;
  hasNoWrap?: boolean;
  shouldInherit?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'span',
  type: 'body',
  size: 'md',
  color: undefined,
  opacity: undefined,
  mb: undefined,
  align: undefined,
  label: undefined,
  lineHeight: undefined,
  width: undefined,
  maxWidth: undefined,
});

const classes = computed(() => {
  return {
    [props.type]: true,
    [props.type + '--' + props.size]: true,
    [props.type + '--align-' + props.align]: !!props.align,
    [props.type + '--bold']: props.bold || props.tag === 'strong',
    [props.type + '--semi-bold']: props.semiBold,
    [props.type + '--muted']: props.muted,
    [props.type + '--margin']: props.margin,
    [props.type + '--uppercase']: props.uppercase,
    [props.type + '--truncated']: props.truncated,
    ['is-dark']: props.isDark,
    ['is-handle']: props.isHandle,
    ['has-whitespace']: props.hasWhitespace,
    ['has-no-wrap']: props.hasNoWrap,
    ['should-inherit']: props.shouldInherit,
    ['mb-' + props.mb]: props.mb,
  };
});
</script>

<template>
  <component
    :is="tag"
    :class="classes"
    :style="{
      color,
      opacity,
      width,
      maxWidth,
      lineHeight,
    }"
  >
    <template v-if="label">
      {{ label }}
    </template>

    <template v-else>
      <slot />

      <span v-if="$slots.muted" class="is-muted">
        <slot name="muted" />
      </span>
    </template>
  </component>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/Typography/Typography.scss';
</style>
