<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  columns?: '1' | '2' | '3';
  columnGap?: 'xs' | 'sm' | 'md' | 'lg';
}

const props = withDefaults(defineProps<Props>(), {
  columns: '1',
  columnGap: 'lg',
});

const classes = computed(() => {
  return {
    ['form-layout']: true,
    ['is-' + props.columns + '-column']: true,
    ['gap-' + props.columnGap]: true,
  };
});
</script>

<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/FormLayout/FormLayout.scss';
</style>
