<script setup lang="ts">
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/solid';
import Support from '@libero/sensor-registry/modules/support/views/modals/Support.vue';
import Clickable from '@libero/ui-framework/Clickable/Clickable.vue';
import FadeMotion from '@libero/ui-framework/FadeMotion/FadeMotion.vue';
import Icon from '@libero/ui-framework/Icon/Icon.vue';
import NavLink from '@libero/ui-framework/NavLink/NavLink.vue';
import { classNames } from '@libero/utilities/class-names';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const { t } = useI18n();
const route = useRoute();

const isOpen = ref(false);

const toggleOpen = () => {
  isOpen.value = !isOpen.value;
};

watch(route, () => {
  isOpen.value = false;
});
</script>

<template>
  <div class="navigation-container" :class="classNames({ isOpen })">
    <Clickable class="navigation-toggle" :onClick="toggleOpen">
      <Icon size="xl">
        <component :is="isOpen ? XMarkIcon : Bars3Icon" />
      </Icon>
    </Clickable>

    <FadeMotion>
      <div v-if="isOpen" class="navigation-backdrop" :onClick="toggleOpen" />
    </FadeMotion>

    <div class="navigation">
      <NavLink class="navigation-link" :to="{ name: 'map.index' }">
        {{ t('navigation.map') }}
      </NavLink>

      <NavLink class="navigation-link" :to="{ name: 'register' }">
        {{ t('navigation.register-sensor') }}
      </NavLink>

      <Support v-slot="{ openModal }">
        <NavLink class="navigation-link" :onClick="openModal">
          {{ t('navigation.contact') }}
        </NavLink>
      </Support>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/sensor-registry/components/global/Navigation/Navigation.scss';
</style>
