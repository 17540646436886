import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: '/register',
    name: 'register',
    component: () => import('./views/pages/Register.vue'),
    meta: {
      title: 'Registreer',
    },
  },
];
