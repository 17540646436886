import '@libero/plugins/ant-design';
import '@libero/plugins/chart';
import '@libero/plugins/dayjs';
import '@libero/theme/app.scss';

import { query, queryOptions } from '@libero/plugins/query';
import { registerSentry } from '@libero/plugins/sentry';
import { i18n } from '@libero/sensor-registry/i18n';
import { router } from '@libero/sensor-registry/router';
import { createPinia } from 'pinia';
import { createApp } from 'vue';

import App from './App.vue';

const app = createApp(App);
const pinia = createPinia();

registerSentry(app, router);

app.use(router);
app.use(pinia);
app.use(i18n);
app.use(query, queryOptions);

app.mount('#app');
