import type ky from 'ky';

import { ApiOptions } from './api-client';
import { generateUrl } from './base-url';

interface JsonApiClient {
  get: <T>(input?: string, options?: ApiOptions) => Promise<T>;
  post: <T>(input?: string, json?: unknown, options?: ApiOptions) => Promise<T>;
  patch: <T>(input?: string, json?: unknown, options?: ApiOptions) => Promise<T>;
  put: <T>(input?: string, json?: unknown, options?: ApiOptions) => Promise<T>;
  delete: <T>(input?: string, options?: ApiOptions) => Promise<T>;
}

const createJsonApiClient =
  (kyInstance: typeof ky) =>
  (path: string): JsonApiClient => {
    const extendedKyInstance = kyInstance.extend({
      prefixUrl: generateUrl(path),
    });

    const instance = <T>(input?: string) => extendedKyInstance(input || '').json<T>();

    instance.get = <T>(input?: string, options?: ApiOptions) =>
      extendedKyInstance.get(input || '', options).json<T>();

    instance.post = <T>(input?: string, json?: unknown, options?: ApiOptions) =>
      extendedKyInstance.post(input || '', { ...options, json: json }).json<T>();

    instance.patch = <T>(input?: string, json?: unknown, options?: ApiOptions) =>
      extendedKyInstance.patch(input || '', { ...options, json: json }).json<T>();

    instance.put = <T>(input?: string, json?: unknown, options?: ApiOptions) =>
      extendedKyInstance.put(input || '', { ...options, json: json }).json<T>();

    instance.delete = <T>(input?: string, options?: ApiOptions) =>
      extendedKyInstance.delete(input || '', options).json<T>();

    return instance;
  };

export { createJsonApiClient };
