import { api } from '@libero/utilities/api-client';
import { ApiOptions } from '@libero/utilities/api-client';

import type { Media } from './types';

const store = (data: FormData, options: ApiOptions): Promise<Media> => {
  return api.post('api/media/uploads', { ...options, body: data, timeout: false }).json();
};

export const mediaApi = {
  name: 'media',
  store,
};
