<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/solid';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import { classNames } from '@libero/utilities/class-names';
import { Modal } from 'ant-design-vue';
import { computed } from 'vue';

interface Props {
  title?: string;
  width?: string;
  isOpen?: boolean;
  isFullHeight?: boolean;
  isFullScreen?: boolean;
  hasNoOffset?: boolean;
  onCancel?: () => void;
}

const props = defineProps<Props>();

const hasNoOffset = computed(() => props.hasNoOffset || !props.title);
</script>

<template>
  <Modal
    class="modal"
    :class="classNames({ hasNoOffset, isFullHeight, isFullScreen })"
    :open="isOpen"
    :width="width"
    :footer="false"
    centered
    :onCancel="() => onCancel?.()"
    destroyOnClose
    :maskClosable="false"
  >
    <template #closeIcon>
      <XMarkIcon v-if="onCancel" />
    </template>

    <template #title>
      <Cluster alignItems="center" justifyContent="space-between">
        <Cluster v-if="title" alignItems="center" minWidth="0">
          <slot name="prefix" />

          <div class="modal-title">
            {{ title }}
          </div>

          <slot name="suffix" />
        </Cluster>

        <Cluster :gap="0" alignItems="center">
          <slot name="actions" />
        </Cluster>
      </Cluster>
    </template>

    <slot />
  </Modal>
</template>

<style lang="scss">
@import '@libero/ui-framework/Modal/Modal.scss';
</style>
