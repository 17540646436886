import GoogleAnalytics from 'react-ga4';

type EventOptions = {
  action: string;
  category: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
  transport?: 'beacon' | 'xhr' | 'image';
};

export const initializeAnalytics = (measurementId?: string): void => {
  if (!measurementId) return;
  GoogleAnalytics.initialize(measurementId);
};

export const trackEvent = (options: EventOptions): void => {
  GoogleAnalytics.event(options);
};
