import { Color } from '@libero/types/Color';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  LineElement,
  PointElement,
  BarElement,
  CategoryScale,
  ChartDataLabels,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  ArcElement,
);

ChartJS.defaults.responsive = true;
ChartJS.defaults.maintainAspectRatio = false;

ChartJS.defaults.font.family = `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji'`;
ChartJS.defaults.font.size = 12;
ChartJS.defaults.font.weight = 600;
ChartJS.defaults.color = Color.gray500;
ChartJS.defaults.borderColor = Color.gray200;

ChartJS.defaults.plugins.legend.position = 'bottom';

ChartJS.defaults.plugins.legend.labels.pointStyle = 'circle';
ChartJS.defaults.plugins.legend.labels.usePointStyle = true;
ChartJS.defaults.plugins.legend.labels.boxWidth = 7.5;
ChartJS.defaults.plugins.legend.labels.boxHeight = 7.5;
ChartJS.defaults.plugins.legend.labels.padding = 16;
ChartJS.defaults.plugins.legend.onClick = () => {
  return;
};

ChartJS.defaults.plugins.tooltip.boxWidth = 10;
ChartJS.defaults.plugins.tooltip.boxHeight = 10;
ChartJS.defaults.plugins.tooltip.usePointStyle = true;
