<script setup lang="ts">
import Support from '@libero/sensor-registry/modules/support/views/forms/Support.vue';
import Modal from '@libero/ui-framework/Modal/Modal.vue';
import ModalContent from '@libero/ui-framework/Modal/ModalContent.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const isOpen = ref(false);

const toggleOpen = () => {
  isOpen.value = !isOpen.value;
};
</script>

<template>
  <slot :openModal="toggleOpen" />

  <Modal
    :isOpen="isOpen"
    width="42rem"
    :title="t('support.contact-support')"
    :footer="false"
    :onCancel="toggleOpen"
  >
    <ModalContent>
      <Support :onClose="toggleOpen" />
    </ModalContent>
  </Modal>
</template>
