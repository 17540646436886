<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/solid';
import { Color } from '@libero/types/Color';
import Clickable from '@libero/ui-framework/Clickable/Clickable.vue';
import Icon from '@libero/ui-framework/Icon/Icon.vue';
import InputError from '@libero/ui-framework/InputError/InputError.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import { Input } from 'ant-design-vue';
import { onMounted } from 'vue';
import { computed, onUnmounted, ref } from 'vue';

interface Props {
  id: string;
  name: string;
  value: string | null;
  type?: 'email' | 'password' | 'hidden' | 'text';
  placeholder?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  width?: string;
  minWidth?: string;
  error?: string;
  autocomplete?: string;
  disabled?: boolean;
  isClearable?: boolean;
  isDirty?: boolean;
  shouldFocus?: boolean;
  onUpdate?: (value: string | null) => void;
  onPressEnter?: (event: Event) => void;
  onMount?: (onFocus?: () => void, shouldFocus?: boolean) => void;
  onUnmount?: () => void;
}

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  placeholder: undefined,
  size: 'md',
  width: undefined,
  minWidth: undefined,
  error: undefined,
  autocomplete: undefined,
  onUpdate: undefined,
  onPressEnter: undefined,
  onMount: undefined,
  onUnmount: undefined,
});

const input = ref<HTMLInputElement>();

const classes = computed(() => {
  return {
    ['size-' + props.size]: true,
    ['is-dirty']: props.isDirty,
  };
});

const showPassword = ref(false);
const passwordFieldType = computed(() => (showPassword.value ? 'text' : 'password'));

const inputType = computed(() => {
  switch (props.type) {
    case 'password':
      return passwordFieldType.value;
    case 'hidden':
      return 'password';
    default:
      return props.type;
  }
});

const togglePassword = () => {
  showPassword.value = !showPassword.value;
};

const handleClear = () => {
  props.onUpdate?.('');
};

defineExpose({
  focus: () => input.value?.focus(),
});

onMounted(() => {
  if (props.shouldFocus) {
    input.value?.focus();
  }

  props.onMount?.(input.value?.focus, props.disabled);
});

onUnmounted(() => {
  props.onUnmount?.();
});
</script>

<template>
  <Stack :gap="0" :flex="width">
    <Input
      :id="id"
      ref="input"
      :name="name"
      :class="classes"
      :value="value || undefined"
      :type="inputType"
      :placeholder="placeholder"
      :disabled="disabled"
      :style="{ minWidth, width, maxWidth: width }"
      :autocomplete="autocomplete ?? 'off'"
      :onUpdate:value="(value) => onUpdate?.(value || null)"
      :onPressEnter="onPressEnter"
    >
      <template #prefix>
        <slot name="icon" />
      </template>

      <template #suffix>
        <Clickable v-if="isClearable && value" :onClick="handleClear">
          <XMarkIcon />
        </Clickable>

        <Clickable v-else-if="type === 'password'" :onClick="togglePassword">
          <Icon :color="Color.gray400">
            <EyeIcon v-if="showPassword" />
            <EyeSlashIcon v-else />
          </Icon>
        </Clickable>
      </template>
    </Input>

    <InputError :message="error" />
  </Stack>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/Input/Input.scss';
</style>
