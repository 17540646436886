<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/solid';
import Clickable from '@libero/ui-framework/Clickable/Clickable.vue';
import Spinner from '@libero/ui-framework/Spinner/Spinner.vue';
import { classNames } from '@libero/utilities/class-names';
import { computed, useSlots } from 'vue';
import { type RouterLocation } from 'vue-router';

interface Props {
  to?: RouterLocation;
  type?: 'submit' | 'button' | 'reset';
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: 'primary' | 'secondary' | 'error';
  appearance?: 'dark' | 'light' | 'outline' | 'link' | 'radio' | 'select' | 'clear';
  maxWidth?: string;
  label?: string;
  ariaLabel?: string;
  isFullWidth?: boolean;
  isCentered?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  shouldOpenBlank?: boolean;
  onClick?: () => void;
  onMousedown?: () => void;
  onMouseup?: () => void;
  onClear?: () => void;
}

const props = withDefaults(defineProps<Props>(), {
  to: undefined,
  type: 'button',
  size: 'md',
  color: 'primary',
  appearance: 'dark',
  maxWidth: undefined,
  label: undefined,
  ariaLabel: undefined,
  shouldOpenBlank: undefined,
  onClick: undefined,
  onMousedown: undefined,
  onMouseup: undefined,
  onClear: undefined,
});

const slots = useSlots();

const hasIcon = computed(() => !!slots.icon);
const hasOnlyIcon = computed(() => !!slots.icon && !slots.default && !slots.label);

const handleClear = (event: Event) => {
  event.stopImmediatePropagation();
  props.onClear?.();
};
</script>

<template>
  <Clickable
    :to="to"
    :type="type"
    class="button"
    :class="
      classNames({
        size,
        color,
        appearance,
        isFullWidth,
        isCentered,
        isActive,
        isDisabled,
        hasIcon,
        hasOnlyIcon,
      })
    "
    :style="{
      maxWidth,
    }"
    activeClass="is-active"
    :isDisabled="isDisabled || isLoading"
    :shouldOpenBlank="shouldOpenBlank"
    :ariaLabel="ariaLabel"
    :onClick="onClick"
    :onMousedown="onMousedown"
    :onMouseup="onMouseup"
  >
    <Spinner v-if="isLoading" class="button__icon" />

    <span v-else-if="$slots.icon" class="button__icon">
      <slot name="icon" />
    </span>

    <span v-if="label" class="button__label">
      {{ label }}
    </span>

    <span v-else-if="$slots.label" class="button__label">
      <slot name="label" />
    </span>

    <span v-else class="button__label">
      <slot />
    </span>

    <span v-if="$slots['icon-right']" class="button__icon-right">
      <slot name="icon-right" />
    </span>

    <Clickable v-if="onClear" class="button__clear" :onClick="handleClear">
      <XMarkIcon />
    </Clickable>
  </Clickable>
</template>

<style lang="scss">
@import '@libero/ui-framework/Button/Button.scss';
</style>
