<script lang="ts" setup>
import { mediaApi } from '@libero/api-client/media/api';
import type { Media } from '@libero/api-client/media/types';
import { ApiError } from '@libero/types/Error';
import Button from '@libero/ui-framework/Button/Button.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import InputError from '@libero/ui-framework/InputError/InputError.vue';
import Link from '@libero/ui-framework/Link/Link.vue';
import Modal from '@libero/ui-framework/Modal/Modal.vue';
import ModalContent from '@libero/ui-framework/Modal/ModalContent.vue';
import ModalActions from '@libero/ui-framework/ModalActions/ModalActions.vue';
import Spinner from '@libero/ui-framework/Spinner/Spinner.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { Upload, UploadDragger, type UploadFile } from 'ant-design-vue';
import { message } from 'ant-design-vue';
import type { RcFile, UploadRequestOption } from 'ant-design-vue/lib/vc-upload/interface';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  id: string;
  name: string;
  value?: Media[];
  hasMultiple?: boolean;
  disabled?: boolean;
  error?: string;
  isButton?: boolean;
  onUploaded?: (media: Media[]) => void;
  onUpdate?: (value: Media[]) => void;
}

const props = withDefaults(defineProps<Props>(), {
  value: () => [],
  error: undefined,
  onUploaded: undefined,
  onUpdate: undefined,
});

const { t } = useI18n();

const isLoading = ref(false);
const items = ref<Media[]>([]);
const responses = ref<Media[]>([]);
const abortController = ref(new AbortController());

const handleAction = async (options: UploadRequestOption<Media>) => {
  isLoading.value = true;

  const file = options.file as RcFile;

  const formData = new FormData();
  formData.append('file', file);
  formData.append('uuid', file.uid);

  mediaApi
    .store(formData, { signal: abortController.value.signal })
    .then((response) => {
      responses.value.push({
        ...response,
        name: file.name.split('.').slice(0, -1).join('.'),
      });

      if (responses.value.length === items.value.length) {
        props.onUploaded?.(responses.value);
        responses.value = [];
        items.value = [];
        isLoading.value = false;
      }

      options.onSuccess?.(response);
    })
    .catch((error: ApiError) => {
      responses.value = [];
      items.value = [];
      isLoading.value = false;

      options.onError?.(error);

      if (error.response?.data) {
        message.error(error.response.data.message);
      } else if (error.response?.status === 413) {
        message.error(t('media.file-too-large'));
      } else {
        message.error(error.message);
      }

      props.onUpdate?.(props.value.filter((item) => item.uuid !== file.uid));
    });
};

const handleUpdate = (value: UploadFile[]) => {
  const files = value as (UploadFile & Media)[];

  const media = files.map((file) => ({
    ...file,
    uuid: file.uuid || file.uid,
    original_url: file.temporary_url || file.url,
    file_name: file.name,
    mime_type: file.mime_type || file.originFileObj?.type,
  }));

  items.value = media.filter((file) => !props.value.some((item) => item.uuid === file.uuid));

  if (props.onUpdate) {
    if (props.hasMultiple) {
      return props.onUpdate(media);
    }

    return props.onUpdate([media[media.length - 1]]);
  }
};

const handleCancel = () => {
  abortController.value.abort();
  abortController.value = new AbortController();
};

const fileList = computed<UploadFile[]>(() =>
  (props.value || items.value).map((media) => ({
    ...media,
    uid: media.uuid,
    status: 'done',
    url: media.temporary_url,
  })),
);

const component = computed(() => (props.isButton ? Upload : UploadDragger));
</script>

<template>
  <div class="file-upload">
    <component
      :is="component"
      :id="id"
      :key="fileList.length"
      :name="name"
      :disabled="disabled"
      :fileList="fileList"
      :customRequest="handleAction"
      :onUpdate:fileList="handleUpdate"
      :showUploadList="false"
      multiple
    >
      <slot v-if="$slots.default" />

      <template v-else>
        <Typography size="sm">
          {{ hasMultiple ? t('media.drag-files') : t('media.drag-file') }}

          <Link size="sm">
            {{ hasMultiple ? t('media.search-files') : t('media.search-file') }}
          </Link>
        </Typography>

        <Typography size="sm">.</Typography>
      </template>
    </component>

    <InputError :message="error" />

    <Modal :title="t('uploading-files')" :isOpen="isLoading">
      <ModalContent>
        <Cluster justifyContent="center">
          <Spinner size="xl" />
        </Cluster>

        <ModalActions>
          <Cluster justifyContent="end">
            <Button appearance="outline" :onClick="handleCancel">
              {{ t('cancel') }}
            </Button>
          </Cluster>
        </ModalActions>
      </ModalContent>
    </Modal>
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/organisms/FileUpload/FileUpload.scss';
</style>
