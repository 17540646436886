import 'dayjs/locale/nl';
import 'dayjs/locale/en';

import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(weekOfYear);

export const setLocale = (locale: string): void => {
  dayjs.locale(locale);
};
