import { Color } from '@libero/types/Color';
import { ConfigProvider, message } from 'ant-design-vue';

ConfigProvider.config({
  theme: {
    primaryColor: Color.primary500,
    errorColor: Color.errorBase,
    successColor: Color.successBase,
  },
});

message.config({
  duration: 5,
});

export { ConfigProvider };
