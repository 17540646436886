import { tenantApi } from '@libero/api-client/tenant/api';
import type { Tenant } from '@libero/api-client/tenant/types';
import { ApiError } from '@libero/types/Error';
import { message } from 'ant-design-vue';
import { defineStore } from 'pinia';
import { type Ref, ref } from 'vue';

type Callback = () => void;

interface Store {
  tenant: Ref<Tenant | undefined>;
  fetchTenant: () => void;
  onFetched: (callback: Callback) => void;
}

export const useTenantStore = defineStore('tenant', (): Store => {
  const tenant = ref<Tenant>();
  const callbacks = ref<Callback[]>([]);

  const fetchTenant = async () => {
    tenant.value = await tenantApi.getTenant().catch((error: ApiError) => {
      if (error.response?.status === 404) {
        location.href = 'https://www.cityview.nl';
      } else if (error.response?.status !== 503 && error.response?.data) {
        message.error(error.response.data.message);
      }

      return undefined;
    });

    callbacks.value.forEach((callback) => callback());
  };

  const onFetched = (callback: Callback) => {
    callbacks.value.push(callback);
  };

  return {
    tenant,
    fetchTenant,
    onFetched,
  };
});
