<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script setup lang="ts">
import { ChevronDownIcon, XMarkIcon } from '@heroicons/vue/24/solid';
import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid';
import { Color } from '@libero/types/Color';
import type { SelectOption, SelectValue, SelectValues } from '@libero/types/Select';
import Clickable from '@libero/ui-framework/Clickable/Clickable.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Icon from '@libero/ui-framework/Icon/Icon.vue';
import InputError from '@libero/ui-framework/InputError/InputError.vue';
import Spinner from '@libero/ui-framework/Spinner/Spinner.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { Select } from 'ant-design-vue';
import type { LabeledValue, SelectValue as AntSelectValue } from 'ant-design-vue/lib/select';
import { FilterFunc } from 'ant-design-vue/lib/vc-select/Select';
import { isEqual, isUndefined } from 'lodash';
import { onUnmounted, toRef, watch } from 'vue';
import { ComponentPublicInstance, computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export interface Props {
  id: string;
  name: string;
  value: any;
  class?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  width?: string;
  minWidth?: string;
  mode?: 'multiple' | 'tags';
  searchValue?: string | null;
  options?: SelectOption[];
  placeholder?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  isClearable?: boolean;
  isRemovable?: boolean;
  isRequired?: boolean;
  shouldSelectFirst?: boolean;
  error?: string;
  filterOption?: boolean | FilterFunc<SelectOption>;
  hasSearchIcon?: boolean;
  isDirty?: boolean;
  isEmpty?: boolean;
  isLoading?: boolean;
  autofocus?: boolean;
  onUpdate?: (value: any) => void;
  onSearch?: (value: string) => void;
  onMount?: (onFocus?: () => void, shouldFocus?: boolean) => void;
  onUnmount?: () => void;
}

const props = withDefaults(defineProps<Props>(), {
  class: undefined,
  size: 'md',
  width: undefined,
  minWidth: undefined,
  mode: undefined,
  searchValue: undefined,
  options: () => [],
  placeholder: undefined,
  error: undefined,
  filterOption: undefined,
  onUpdate: undefined,
  onSearch: undefined,
  onMount: undefined,
  onUnmount: undefined,
});

const { t } = useI18n();

const select = ref<ComponentPublicInstance<typeof Select>>();
const initialValues = ref<SelectValues>(null);
const options = toRef(props, 'options');

const classes = computed(() => {
  return {
    ['select']: true,
    ['size-' + props.size]: true,
    ['is-disabled']: !!props.disabled,
    ['is-dirty']: !!props.isDirty,
    ['is-full-width']: !!props.fullWidth,
    ['has-search-icon']: !!props.hasSearchIcon,
    [props.class || '']: !!props.class,
  };
});

const getOptionValue = (value: number | string | LabeledValue) => {
  if (typeof value === 'object') {
    return value.value;
  } else {
    return value;
  }
};

const checkValueRemoved = (value: SelectValues) => {
  if (Array.isArray(value) && Array.isArray(initialValues.value)) {
    return !initialValues.value.every((initialValue) => value.some((v) => v === initialValue));
  }

  return false;
};

const handleChange = (value: AntSelectValue) => {
  if (!value) {
    return props.onUpdate?.(null);
  }

  if (Array.isArray(value)) {
    if (!props.isRemovable && checkValueRemoved(value as SelectValues)) {
      return;
    }

    return props.onUpdate?.(value.map(getOptionValue));
  }

  return props.onUpdate?.(getOptionValue(value));
};

const defaultFilterOption = (value: string, option?: SelectOption) => {
  return option?.label.toLowerCase().indexOf(value.toLowerCase()) >= 0;
};

const isMultiple = computed(() => props.mode === 'multiple');
const values = computed(
  () => (Array.isArray(props.value) ? props.value : [props.value]).filter(Boolean) as SelectValue[],
);

const handleUpdateValue = (value?: SelectOption[], oldValue?: SelectOption[]) => {
  if (value && isEqual(value, oldValue)) return;

  if (props.isRequired && !isMultiple.value && options.value.length === 1) {
    return props.onUpdate?.(options.value[0].value || null);
  }

  if (!props.value && props.shouldSelectFirst && options.value?.[0]?.value) {
    return props.onUpdate?.(isMultiple.value ? [options.value[0].value] : options.value[0].value);
  }

  if (!props.value || !value || props.searchValue) return;

  const filteredValues = values.value.filter(
    (value) => options.value?.some((option) => option.value === value),
  );

  if (!isEqual(values.value, filteredValues)) {
    props.onUpdate?.(isMultiple.value ? filteredValues : filteredValues.at(0) || null);
  }
};

watch(options, handleUpdateValue);

onMounted(() => {
  handleUpdateValue();

  initialValues.value = props.value;

  if (props.autofocus) {
    select.value?.focus();
  }

  props.onMount?.(select.value?.focus, props.disabled);
});

onUnmounted(() => {
  props.onUnmount?.();
});
</script>

<template>
  <Stack :gap="0" :width="width">
    <Select
      :id="id"
      ref="select"
      :name="name"
      :class="classes"
      :value="value || undefined"
      :disabled="disabled"
      :placeholder="placeholder || t('make-choice')"
      :options="options"
      :mode="mode"
      :searchValue="searchValue || undefined"
      :showSearch="!!onSearch"
      :allowClear="isClearable"
      :allowRemove="isRemovable"
      :notFoundContent="isEmpty || isLoading ? undefined : false"
      showArrow
      :style="{ minWidth, width }"
      :filterOption="isUndefined(filterOption) ? defaultFilterOption : filterOption"
      :defaultActiveFirstOption="false"
      :onChange="handleChange"
      :onSearch="onSearch"
      maxTagCount="responsive"
      :dropdownMatchSelectWidth="false"
      placement="bottomRight"
      :maxTagPlaceholder="(items: any[]) => `+${items?.length}`"
    >
      <template #option="option">
        <slot v-if="$slots.option" name="option" :option="option" />

        <Cluster v-else alignItems="center" :gap="0.375">
          <Icon v-if="option.icon" :color="option.iconColor">
            <component :is="option.icon" />
          </Icon>

          <Typography v-if="option.label" isDark>
            {{ option.label }}
          </Typography>

          <template v-if="option?.subLabel">
            <Typography :color="Color.gray400">
              {{ '—' }}
            </Typography>

            <Typography :color="Color.gray500">
              {{ option?.subLabel }}
            </Typography>
          </template>
        </Cluster>
      </template>

      <template #optionLabel="option">
        <slot v-if="$slots.optionLabel" name="optionLabel" :option="option" />

        <Cluster v-else-if="option" alignItems="center" :gap="0.375" minHeight="1.625rem">
          <Icon v-if="option.icon" :size="size" :color="option.iconColor">
            <component :is="option.icon" />
          </Icon>

          <Typography v-if="option.label" shouldInherit truncated lineHeight="1.625rem">
            {{ option.label }}
          </Typography>
        </Cluster>
      </template>

      <template v-if="$slots.tagRender" #tagRender="{ option, closable, onClose }">
        <span class="ant-select-selection-item">
          <span class="ant-select-selection-item-content">
            <slot name="tagRender" :option="option" />
          </span>

          <Clickable
            v-if="closable && isRemovable"
            class="ant-select-selection-item-remove"
            :onClick="onClose"
          >
            <XMarkIcon />
          </Clickable>
        </span>
      </template>

      <template #suffixIcon>
        <MagnifyingGlassIcon v-if="hasSearchIcon" />
        <ChevronDownIcon v-else-if="mode !== 'tags' && !disabled" />
      </template>

      <template #clearIcon>
        <XMarkIcon />
      </template>

      <template #removeIcon>
        <XMarkIcon v-if="isRemovable" />
      </template>

      <template #notFoundContent>
        <Cluster v-if="isLoading" alignItems="center">
          <Spinner />

          <Typography>
            {{ t('loading') }}
          </Typography>
        </Cluster>

        <Typography v-else-if="isEmpty">
          {{ t('no-results') }}
        </Typography>
      </template>
    </Select>

    <InputError :message="error" />
  </Stack>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/Select/Select.scss';
</style>

<style lang="scss">
@import '@libero/ui-framework/Select/Dropdown.scss';
</style>
