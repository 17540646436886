import { User } from '@libero/api-client/user/types';
import packageJson from '@libero/plugins/../../package.json';
import * as Sentry from '@sentry/vue';
import { upperFirst } from 'lodash';
import type { App } from 'vue';
import { type Router } from 'vue-router';

export const registerSentry = (app: App, router: Router): void => {
  if (!import.meta.env.VITE_SENTRY_TOKEN) return;

  const isProduction = ['production'].includes(import.meta.env.VITE_ENVIRONMENT);

  Sentry.init({
    environment: upperFirst(import.meta.env.VITE_ENVIRONMENT),
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({ router })],
    release: packageJson.version,
    tracesSampleRate: isProduction ? 0.1 : 1,
  });
};

export const setSentryUser = (user: User): void => {
  Sentry.setUser({
    id: user.id,
    username: user.full_name,
    email: user.email,
  });
};
