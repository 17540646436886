<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  value?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  required?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  value: undefined,
  size: 'md',
});

const classes = computed(() => {
  return {
    ['label']: true,
    ['label--' + props.size]: true,
    ['label--is-required']: !!props.required,
  };
});
</script>

<template>
  <label :class="classes">
    <span v-if="value">{{ value }}</span>
    <span v-else><slot /></span>
  </label>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/Label/Label.scss';
</style>
