import { RouteLocationNamedRaw } from 'vue-router';

export const getRouteScope = (route: RouteLocationNamedRaw): string => {
  return route.name?.toString()?.split('.')?.at(0) ?? '';
};

export const generateScopedPath = (basePath: string, scope: string, key: string): string => {
  return key.startsWith(scope) ? basePath : `${scope}/${basePath}`;
};

export const generateScopedName = (baseName: string, scope: string, key: string): string => {
  return key === scope ? baseName : `${key}.${baseName}`;
};

export const getScopedRoute = (key: string, scope?: string): string => {
  if (!scope) return key;
  if (key?.startsWith(scope)) return key;

  return `${scope}.${key}`;
};
