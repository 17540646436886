<script setup lang="ts">
import Title from '@libero/organisms/Title/Title.vue';
import Navigation from '@libero/sensor-registry/components/global/Navigation/Navigation.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<template>
  <div class="header">
    <div>
      <Title size="sm" :title="t('sensor-registry')" />
    </div>

    <Navigation />
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/sensor-registry/components/global/Header/Header.scss';
</style>
