export type ColorKeys = 'gray' | 'primary' | 'secondary' | 'success' | 'warning' | 'error';

export enum Color {
  inherit = 'inherit',

  gray900 = '#0e1724',
  gray800 = '#202937',
  gray700 = '#344054',
  gray600 = '#434c5e',
  gray500 = '#545e72',
  gray400 = '#667085',
  gray300 = '#d0d5dd',
  gray200 = '#e4e7ec',
  gray100 = '#ebeef2',
  gray050 = '#f2f4f7',
  gray025 = '#f9f9fb',

  primary900 = '#233876',
  primary800 = '#1e429f',
  primary700 = '#1a56db',
  primary600 = '#1c64f2',
  primary500 = '#3f83f8',
  primary400 = '#76a9fa',
  primary300 = '#a4cafe',
  primary200 = '#c3ddfd',
  primary100 = '#e1effe',
  primary050 = '#ebf5ff',
  primary025 = '#f8fbff',

  secondary900 = '#8b002e',
  secondary800 = '#a30036',
  secondary700 = '#cb0043',
  secondary600 = '#fa0b5a',
  secondary500 = '#fe3678',
  secondary400 = '#ff6f9e',
  secondary300 = '#ff9bb9',
  secondary200 = '#ffc5d7',
  secondary100 = '#ffd7e3',
  secondary050 = '#ffe8ef',
  secondary025 = '#fff3f7',

  whiteFull = '#ffffff',
  whiteOff = '#fcfcfd',

  errorDarker = '#a11e00',
  errorDark = '#bc2300',
  errorBase = '#e02a01',
  errorLight = '#e99f90',
  errorLighter = '#fde9e7',
  errorLightest = '#fef1f0',

  successDarker = '#205b0d',
  successDark = '#28780d',
  successBase = '#49a02c',
  successLight = '#b1d0a8',
  successLighter = '#edf6ea',
  successLightest = '#f3faf1',

  warningDarker = '#a65a00',
  warningDark = '#c57600',
  warningBase = '#ff9400',
  warningLight = '#f8bb67',
  warningLighter = '#fff1df',
  warningLightest = '#fff6e9',

  darkRed = '#B12222',
  darkOrange = '#D17333',
  darkYellow = '#DDBA00',
  aquaBlue = '#008A8A',
  darkBlue = '#254089',
  blue = '#006BB8',
  brown = '#634D2C',
  purple = '#8405A7',
  green = '#328A14',
  grayBlue = '#7989A5',
}
