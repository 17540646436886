import { kebabCase } from 'lodash';

type Classes = Record<string, boolean | string | undefined>;

export const classNames = (classes: Classes): Classes =>
  Object.fromEntries(
    Object.entries(classes).map(([key, value]) => {
      if (typeof value === 'string') {
        return [`is-${kebabCase(key)}-${value}`, true];
      }

      return [key.replace(/([a-z0–9])([A-Z])/g, '$1-$2').toLowerCase(), value];
    }),
  );
