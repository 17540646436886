import { generateContainerRoutes } from '@libero/sensor-registry/modules/container/routes';
import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: '/map',
    name: 'map.index',
    component: () => import('./views/pages/Index.vue'),
    children: [...generateContainerRoutes('map')],
    meta: {
      title: 'Map',
      permission: 'container.index',
    },
  },
  {
    path: '/',
    redirect: { name: 'map.index' },
  },
];
