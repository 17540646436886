<script setup lang="ts">
import { Container } from '@libero/api-client/container/types';
import { sensorRegistryApi } from '@libero/api-client/sensor-registry/api';
import { StoreSupportRequest } from '@libero/api-client/sensor-registry/types';
import { useForm } from '@libero/hooks/useForm';
import Media from '@libero/organisms/Media/Media.vue';
import Button from '@libero/ui-framework/Button/Button.vue';
import Checkbox from '@libero/ui-framework/Checkbox/Checkbox.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Form from '@libero/ui-framework/Form/Form.vue';
import FormLayout from '@libero/ui-framework/FormLayout/FormLayout.vue';
import Input from '@libero/ui-framework/Input/Input.vue';
import InputGroup from '@libero/ui-framework/InputGroup/InputGroup.vue';
import Label from '@libero/ui-framework/Label/Label.vue';
import Link from '@libero/ui-framework/Link/Link.vue';
import ModalActions from '@libero/ui-framework/ModalActions/ModalActions.vue';
import Select from '@libero/ui-framework/Select/Select.vue';
import SubLabel from '@libero/ui-framework/SubLabel/SubLabel.vue';
import Textarea from '@libero/ui-framework/Textarea/Textarea.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { useMutation } from '@tanstack/vue-query';
import { message } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';

interface Props {
  container?: Container;
  onClose: () => void;
}

const props = defineProps<Props>();

const { t } = useI18n();

const form = useForm<StoreSupportRequest>({
  name: null,
  email: null,
  subject: null,
  message: null,
  media: [],
  privacy_policy: false,
  container_id: props.container?.id || null,
});

const { mutateAsync, isPending } = useMutation({
  mutationFn: sensorRegistryApi.support,
  onSuccess: () => {
    message.success(t('support.sent'));
    props.onClose();
  },
});

const handleSubmit = () => {
  form.submit(mutateAsync);
};
</script>

<template>
  <Form v-bind="form.registerForm(handleSubmit)">
    <FormLayout>
      <Typography>
        {{ t('support.ask-question-description') }}
      </Typography>

      <InputGroup>
        <Label required for="name" :value="t('support.name')" />
        <Input v-bind="form.register('name')" />
      </InputGroup>

      <InputGroup>
        <Label required for="email" :value="t('support.email')" />
        <SubLabel :value="t('support.email-note')" />
        <Input type="email" v-bind="form.register('email')" />
      </InputGroup>

      <InputGroup v-if="!container">
        <Label required for="subject" :value="t('support.subject')" />

        <Select
          :placeholder="t('support.choose-subject')"
          :options="
            ['damage', 'malfunction', 'task', 'question', 'project', 'inspection'].map((name) => ({
              label: t(`support.subject-options.${name}`),
              value: name,
            }))
          "
          v-bind="form.register('subject')"
        />
      </InputGroup>

      <InputGroup>
        <Label
          required
          for="message"
          :value="container ? t('support.your-question') : t('support.description')"
        />
        <Textarea v-bind="form.register('message')" />
      </InputGroup>

      <InputGroup>
        <Label for="media" :value="t('support.files')" />
        <SubLabel :value="t('support.files-note')" />
        <Media v-bind="form.register('media')" />
      </InputGroup>

      <InputGroup>
        <Checkbox v-bind="form.register('privacy_policy')">
          <Typography>{{ t('support.privacy-policy-note') }}</Typography>

          <Link>
            {{ t('support.privacy-policy') }}
          </Link>

          <Typography>.</Typography>
        </Checkbox>
      </InputGroup>
    </FormLayout>

    <ModalActions>
      <Cluster justifyContent="end">
        <Button appearance="outline" :onClick="onClose">
          {{ t('cancel') }}
        </Button>

        <Button :label="t('send')" type="submit" :isLoading="isPending" />
      </Cluster>
    </ModalActions>
  </Form>
</template>
