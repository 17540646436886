<script setup lang="ts">
import Clickable from '@libero/ui-framework/Clickable/Clickable.vue';
import { classNames } from '@libero/utilities/class-names';
import { computed } from 'vue';
import type { RouteLocationRaw, RouterLocation } from 'vue-router';
import { useRoute, useRouter } from 'vue-router';

interface Props {
  to?: RouterLocation;
  href?: string;
  isDisabled?: boolean;
  isPartialActive?: boolean;
  onClick?: () => void;
}

const props = withDefaults(defineProps<Props>(), {
  to: undefined,
  href: undefined,
  onClick: undefined,
});

const router = useRouter();
const route = useRoute();

const isActive = computed(() => {
  if (!props.isPartialActive) return false;
  if (!props.to) return false;

  const routePath = router.resolve(props.to as RouteLocationRaw).path as string;

  return routePath === route.path || route.path.startsWith(routePath);
});

const isDisabled = computed(() => props.isDisabled || (!props.href && !props.to && !props.onClick));
</script>

<template>
  <Clickable
    class="nav-link"
    :class="classNames({ isActive, isDisabled })"
    :to="to"
    :href="href"
    :isDisabled="isDisabled"
    activeClass="is-active"
    :onClick="onClick"
  >
    <slot />
  </Clickable>
</template>
