import { QueryClient, VueQueryPlugin, type VueQueryPluginOptions } from '@tanstack/vue-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: 60000,
    },
  },
});

export const queryOptions: VueQueryPluginOptions = {
  queryClient,
};

export const query = VueQueryPlugin;
