import { generateScopedName, generateScopedPath } from '@libero/utilities/generic-routing';
import type { RouteRecordRaw } from 'vue-router';

export const generateContainerRoutes = (key: string): RouteRecordRaw[] => [
  {
    path: generateScopedPath(':id', 'container', key),
    name: generateScopedName('container.show', 'container', key),
    component: () => import('./views/drawers/Show.vue'),
    props: { scope: key },
    meta: { delay: true },
  },
];

export const routes: RouteRecordRaw[] = [];
