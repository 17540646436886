<script setup lang="ts">
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/vue/24/solid';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { computed } from 'vue';

interface Props {
  size?: 'sm' | 'md' | 'lg';
  type?: 'info' | 'success' | 'error' | 'warning';
}

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  type: 'info',
});

const classes = computed(() => {
  return {
    alert: true,
    ['alert--' + props.size]: true,
    ['alert--' + props.type]: true,
  };
});
</script>

<template>
  <div :class="classes">
    <XCircleIcon v-if="type === 'error'" class="alert__icon" />
    <CheckCircleIcon v-else-if="type === 'success'" class="alert__icon" />
    <InformationCircleIcon v-else-if="type === 'info'" class="alert__icon" />
    <ExclamationCircleIcon v-else class="alert__icon" />

    <Typography tag="div" :size="size" class="alert__body">
      <slot />
    </Typography>
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/Alert/Alert.scss';
</style>
